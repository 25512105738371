@import '__importable.scss';
.container {
	max-width: 64rem !important;
	height: 47.6rem !important;
	padding: 2.4rem !important;

	@media screen and (min-width: $media-sm) {
		translate: -50% 0 !important;
		bottom: 0 !important;
		top: auto !important;
		border-radius: $radius-6 $radius-6 0 0 !important;
	}


	span[data-add-to-cart-text]::after {
		position: absolute !important;
		left: 50% !important;
		translate: -50% !important;
	}
}

.button {
	width: 100%;

	@media screen and (min-width: $media-sm) {
		width: 9rem;
		flex-shrink: 0;
	}
}