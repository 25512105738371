@import '__importable.scss';
.container {
	display: grid;
	justify-content: flex-start;
	grid-column-gap: 0.8rem;
	grid-template-areas:
		'icon label'
		'icon number';
	grid-template-columns: auto 1fr;

	&[data-status='cancelled'] {
		grid-template-columns: auto auto;
	}
}

.icon {
	grid-area: icon;
	height: 100%;
	width: 100%;
	max-height: 6.4rem;

	svg {
		height: inherit;
		max-height: inherit;
		max-width: 6.4rem;
		width: 100%;
	}
}

.number {
	grid-area: number;
	width: min-content;
	color: $blue-2;
	border-radius: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.4rem;
	position: relative;
	left: -0.25rem;

	@media (hover: hover) {
		&:hover {
			opacity: 1;
			color: $blue-1;
			background-color: $gray-2;
			transition: $transition-3;
		}
	}
}

.contact {
	color: $blue-2;
	transition: $transition-3;
	border-radius: 0.4rem;

	@media (hover: hover) {
		&:hover {
			color: $blue-1;
			background-color: $gray-2;
			transition: $transition-3;
		}
	}
}


@media screen and (min-width: $media-md) {
	.container {
		grid-column-gap: 1.6rem;
		grid-template-columns: 6.4rem 1fr;
	}
}