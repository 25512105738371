@import '__importable.scss';
.tracker {
	grid-area: tracker;
}

.container {
	background-color: $white;
	border: 1px solid $gray-2;
	border-radius: 0.8rem;
	padding: 1.6rem;
}

.status-list {
	display: flex;
	flex-flow: column;
	margin-left: 1.6rem;
	position: relative;
	top: -0.4rem;

	li {
		&:not(:last-of-type) {
			margin-bottom: 5rem;
		}

		@media screen and (max-width: $media-md) {
			&:nth-of-type(2) {
				margin-bottom: calc(5rem - 2.4rem);
			}
		}

		@media screen and (max-width: $media-xsm) {
			&:nth-of-type(3) {
				margin-bottom: 3rem;
			}
		}
	}

	>* {
		margin: 0;
	}

	.disabled>* {
		color: $gray-3;
	}
}

.status {
	margin-block: 1.6rem;
	display: flex;

	svg {
		flex-shrink: 0;
	}
}

.divider {
	grid-area: divider;
	color: $gray-2;
}