@import '__importable.scss';
.container {

	margin-bottom: 2.4rem;
	padding-bottom: 2.4rem;

	border-top: 1px solid $gray-2;
	border-bottom: 1px solid $gray-2;

    @media screen and (min-width: $media-sm) {
		margin-bottom: 4rem;
    }

}

.notification {

	background-color: $lightblue-tint;
	color: $blue;
	padding: 1.6rem;
	border-radius: $radius-4 $radius-4 0 0;
	border: 1px solid $gray-2;
	border-collapse: collapse;
	align-items: flex-start;

	> *:first-child {
		flex-shrink: 0;
	}

	&--purchased {
		background-color: $green-light;
		color: $green-shade;
	}

	&--ineligable {
		background-color: $lightpink-tint;
		color: $orange-shade;

	}


	@media screen and (min-width: $media-sm) {
		align-items: center;
	}
}

.upsell {
	border: 1px solid $gray-2;
	border-top: 0px;
	background-color: $white;
	border-radius: 0 0 $radius-4 $radius-4;
}

.check {
	border: 2px solid $green-shade;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	padding: 3px 3px 3px 4px;

	path {
		stroke-width: 2px;
	}
}

.info {
	border: 2px solid transparent;
	overflow: visible;

	path, circle {
		stroke-width: 2px;
	}
}

.upsellActions {
	flex-direction: column;
	gap: 0.8rem;

	@media screen and (min-width: $media-sm) {
		flex-direction: row;
		gap: 1.6rem;
	}
}

.pairCareCard {
	border: 1px solid $gray-2;
	border-radius: $radius-2;
	
	overflow: hidden;

	img {
		border-right: 1px solid $gray-2;
	}

	> *:first-child {
		flex-shrink: 0;
	}

	> *:last-child {

		padding: 0.8rem;
		
		@media screen and (min-width: $media-sm) {
			padding: 1.6rem 0.8rem;
		}
	}

}

.accordionHeader {
	padding-inline: 0rem !important;
	margin-top: 0 !important;
}

.accordionRow {
	padding: 2.4rem 0 0 !important;
	border: 0px !important;

	&--desktop {

		cursor: initial !important;

		> div:last-child {
			svg{
				display: none !important;
			}
		}

		&:hover {
			background-color: initial !important;
		}
	
		&:active {
			background-color: initial !important;
		}
	}
}