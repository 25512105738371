@import '__importable.scss';
.history {
	min-height: unset;
	position: relative;

	>div {
		width: 100%;
	}

	&__animation-wrapper {
		height: inherit;
	}

	&__list-container {
		height: 100%;
	}
}


.details {
	&__list-container {
		height: inherit;
	}
}

.history,
.details {
	&__list-container {
		overflow-y: scroll;
		overflow-x: hidden;
		@include noScrollBar;
		@include padding-block;
		@include padding-inline;
	}
}