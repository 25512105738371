@import '__importable.scss';
.label {
	border-radius: $radius-4 $radius-4 0 0;
	padding: 1.6rem;
	border-top: 1px solid $gray-2;
	border-left: 1px solid $gray-2;
	border-right: 1px solid $gray-2;

	&--error {
		background: $lightpink-1;
		color: $orange-3;

		svg {
			path {
				fill: $orange-3;
			}

			circle {
				stroke: $orange-3;
			}
		}
	}

	&--success {
		background: $green-light;
		color: $green-shade;

		svg {
			fill: transparent;
			path {
				stroke: $green-shade;
				fill: transparent;
			}

			circle {
				stroke: $green-shade;
			}
		}
	}
}

.submission {
	width: 100%;
	padding: 1.6rem;
	background-color: $gray-0;
	border: 1px solid $gray-2;
	border-radius: 0 0 $radius-4 $radius-4;
}

.hidden-upload {
	display: none;
}

.error {
	color: $orange-3;
	margin-top: 0.4rem;
	margin-left: 0.8rem;
}

.success-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 1.6rem;
	gap: 0.8rem;
	width: 100%;
	background: $gray-1;
	border-radius: 0.8rem;

	.name {
		color: $gray-4;
	}

	.uploaded {
		color: $green-3;
		margin-left: 1ch;
	}
}

.manual-prescription-container {
	position: relative;
	width: 100%;

	.upload-lozenge {
		position: absolute;
		top: 0.8rem;
		left: 0.8rem;
	}
}

.photo-placeholder {
	margin: 1.6rem 0;
	border-radius: 4px;
	border: 1px solid $gray-2;

	.file-icon {
		color: $green-4;
		background-color: $green-light;
		border-right: 1px solid $gray-2;
		min-width: 4rem;
		height: 4rem;
	}
}

.prescription-modal {
	max-width: 64rem;
	max-height: 96vh;
	height: 96vh;

	@media screen and (width > $media-sm-max) {
		max-height: 87rem;
		height: 88vh;
	}
}
