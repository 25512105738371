@import '__importable.scss';
.back-button {
	color: $blue-2;
	width: max-content;
	padding: 0.4rem 0.8rem 0.4rem 0;
	margin-bottom: 1.6rem;
	border-radius: $radius-2;
	position: relative;
	left: -1rem;
	cursor: pointer;

	svg {
		width: 2.4rem;
		height: 1.6rem;
	}

	@media (hover: hover) {
		&:hover {
			color: $blue-1;
			background-color: $gray-2;
			transition: $transition-3;
		}
	}
}

.button-wrapper {
	padding-top: 1.6rem;
}

.order-details-wrapper {
	width: 100%;
	padding: 0;
	flex-flow: row wrap;

	>div {
		flex: 1;
		min-width: 300px;
	}

	@include noScrollBar;

	@media screen and (width <= $media-md-max) {
		flex-flow: column;
		&[data-is-logout='true']{
			padding: 0;
		}
	}
}

.error-wrapper {
	width: 100%;
	margin-top: 6.4rem;
	background-color: $gray-0;
	border: 1px solid $gray-2;
	border-radius: 0.8rem;
	padding: 1.6rem;
}
