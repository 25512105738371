@import '__importable.scss';
.buttons-container {
	position: sticky;
	bottom: 0;
	background: $gray-0;
	padding: 1.6rem;
	border-top: 1px solid $gray-2;
	width: 100%;
	
	@media screen and (width > $media-sm-max) {
		padding: 1.6rem 3.2rem 3.2rem;
	}
}

.modal-close-button {
	position: sticky;
	background: $gray-0;
	top: 1rem;
	left: 90%;
	border-radius: $radius-round;
	z-index: $zindex-max + 10;
	pointer-events: visible;

	@media screen and (width > $media-sm-max) {
		left: 93%;
	}
}

.exit-modal-close-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
}

.modal-close {
	padding: 3.2rem !important;
	max-width: 48rem !important;
}

.steps-container {
	@include noScrollBar;
	overflow-y: scroll;
	position: relative;
	min-height: 88%;
}
