@import '__importable.scss';
.details {
    grid-area: details;
}

.container {
    background-color: $white;
    border: 1px solid $gray-2;
    border-radius: $radius-4;
    padding: 1rem;
}

.divider {
    grid-area: divider;
    color: $gray-2;
}

.prescription {
    &-container {
        margin-bottom: rem-calc(40px);
    }

    &-list {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        gap: 4rem;
    }
}
