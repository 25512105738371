@import '__importable.scss';
.skeleton-loader {
	width: 100%;
	height: 2.4rem;
	display: block;
	@include skeleton-loader();

	&-list {
		display: flex;
		flex-direction: column;
		gap: 1.6rem;
		width: 100%;
		height: calc(100vh - 12rem);
		overflow-y: scroll;

		@include noScrollBar;
	}

	&-length {
		@extend .skeleton-loader;
		width: 2ch;
		height: 2.4rem;
		margin-right: 0.5ch;
	}

	&-title,
	.title {
		@extend .skeleton-loader;
		width: 12.8rem;
		height: 2.1rem;
	}

	&-tracking {
		@extend .skeleton-loader;
		width: 16rem;
	}

	&-status {
		@extend .skeleton-loader;
		width: 9.6rem;
		height: 2.4rem;
	}

	&-card {
		$card-padding: 1.6rem;
		background-color: $white;
		border: 1px solid $gray-2;
		border-radius: $radius-4;
		padding: $card-padding;

		> span:not(:first-child) {
			margin-top: 1rem;
		}

		> ul {
			list-style: disc;

			li {
				@extend .skeleton-loader;
				height: 2.1rem;
				width: 16rem;
				margin-top: 0.8rem;
				margin-left: 0.8rem;
				list-style: disc;
			}
		}
	}

	&-details {
		grid-area: details;
	}

	&-tracker {
		grid-area: tracker;

		&-slot {
			@extend .skeleton-loader;
			height: 6.4rem;
			margin-block: 1.6rem;
		}
	}
}

.container {
	background-color: $white;
	border: 1px solid $gray-2;
	border-radius: 0.8rem;
	padding: 1.6rem;
}

.product {
	display: flex;
	gap: 1.2rem;
}

.image,
.title,
.product-title,
.caption,
.price,
.line,
.total {
	@extend .skeleton-loader;
}

.image {
	width: 6.4rem;
	height: 6.4rem;
}

.product-title {
	width: 10rem;
	height: 2.1rem;
	margin-bottom: 0.8rem;
}

.caption {
	width: 4.8rem;
	height: 2.4rem;
}

.price-container {
	display: flex;
	margin-bottom: 1.6rem;
}

.line {
	height: 2.4rem;
	width: 12.8rem;
}

.price {
	width: 3.2rem;
	height: 1.4rem;
	margin-left: auto;
}

.divider {
	grid-area: divider;
	color: $gray-2;
}
